// @ts-nocheck
import {
  Redirect,
  Route as ReactRoute,
  RouteProps as ReactRouteProps,
} from 'react-router-dom';

import useAuth from '../hooks/useAuth';

interface RouteProps extends ReactRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType<any>;
}

const Route = ({
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps) => {
  const { user } = useAuth();

  return (
    <ReactRoute
      {...rest}
      render={({ location }) => {
        return isPrivate && !!!user ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : (
          <Component location={location} />
        );
      }}
    />
  );
};

export default Route;
